<template>
	<div class="bordered">
		<Title>企业人才服务</Title>
		<div class="m_xcourse">
			<h5>企业需求完成率</h5>
			<div class="bg" :style="{width: retaFun + '%'}"></div>
			<div class="column2"><countTo :startVal="0" :endVal="retaFun" :duration="CountAnimationDuration"></countTo>%</div>
		</div>
		<div class="m_xares">
			<div class="line" v-for="(v, i) in essenFun" :key="i">
				<countTo :startVal="0" :endVal="v.value" :duration="1000" class="value"></countTo>
				<p>{{ v.name }}</p>
			</div>
		</div>
		<BaseEchart :option="option" style="width: 100%; height: 245px"></BaseEchart>
	</div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import countTo from 'vue-count-to';
import BaseEchart from '@/components/BaseEchart';
import Title from '../components/Title';
import { mutBarChart } from '../common';
import { randomByLevel } from '@/views/homePage/common';
import { CountAnimationDuration } from '@/views/homePage/common';
export default {
	components: { Title, BaseEchart, countTo },
	data() {
		return {
			CountAnimationDuration,
			option: undefined,
			reta: 75,
			essenArr: [{value: 1652, name: '合作企业'}, {value: 6952, name: '企业人才服务产品'}, {value: 2365, name: '服务频次'}],
		};
	},
	computed: {
		...mapState('mapData', ['mapLevel', 'mapDataArr', 'addressInfo']),
		retaFun(){
      return this.reta / (this.mapLevel + 1)
    },
    essenFun(){
      return this.essenArr.map(s => {
        return {
          ...s,
          value: s.value / (this.mapLevel + 1)
        }
      })
    }
	},
	watch: {
    mapDataArr: {
      handler() {
        const dataX = this.mapDataArr.map((d) => {
          return { name: d.regionName, value: randomByLevel({level: this.mapLevel}) };
        })
        this.option = mutBarChart({ 
					leArr: ['教师总数', '名师讲堂', '中医类'],
					xData: dataX.map((d) => d.name), 
					yFirst: dataX.map((d) => d.value * 8), 
					ySecond: dataX.map((d) => d.value * 10),
					yThird: dataX.map((d) => d.value * 7) 
				});
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/theme/bigScreen.scss';
.bordered{
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
}
.m_xcourse{
  background: rgba(28, 135, 255, .25);
  margin: 18px 18px 0;
  font-size: 14px;
  color:#fff;
  font-weight: bold;
  display: flex;
  align-items: center;
	position: relative;
	h5{
		height: 50px;
		line-height: 50px;
		box-sizing: border-box;
		padding-left: 16px;
		font-size: 14px;
		font-weight: bold;
		color: #FFFFFF;
		position: absolute;
		left: 0;
	}
  .column2{
    color:#00FFFF;
    font-size: 24px;
		font-weight: normal;
		position: absolute;
		right: 18px;
		height: 50px;
		line-height: 50px;
  }
	.bg{
		background: linear-gradient(90deg, #40A4E3 0%, #4AAEFF 100%);
		height: 50px;
	}
}
.m_xares{
	display: flex;
	padding: 12px 8px;
	text-align: center;
	.line{
		flex: 1;
		height: 70px;
		background: rgba(28,135,255,0.25);
		margin: 0 8px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	p{
		font-size: 12px;
		color:#fff;
		padding-top: 4px;
	}
}
.value {
	color: $main-text;
	font-size: 20px;
}
</style>
